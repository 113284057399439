import Layout from '../../components/layout';
import React from 'react';
import SearchField from '../../components/search';
import Seo from '../../components/seo';

function GermanSearch({ locale='de' }) {
  const localizedUrls = {
    de: '/de/suche',
    en: '/en/search'
  }

  return (
    <>
      <Seo title='Suche'/>
      <Layout locale='de' localizedUrls={localizedUrls}>
        <SearchField/>
      </Layout>
    </>
  )
}

export default GermanSearch;
